<!--
 * @Description: 估价页面
 * @Author: huacong
 * @Date: 2021-05-18 14:29:38
 * @LastEditTime: 2021-07-27 15:28:45
 * @LastEditors: huacong
-->
<template>
  <div class="assessBox">
    <div class="assessTitle">
      <p>证书估价让您议价不在被动</p>
      <p>
        今日已有<span class="font-red">{{ num }}</span
        >位建筑人才查询
      </p>
    </div>
    <el-form :model="postInfo">
      <el-form-item>
        <el-input placeholder="姓名" v-model="postInfo.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-radio-group
          v-model="postInfo.sex"
          size="medium"
          style="width: 100%"
        >
          <el-radio-button label="男" style="width: 50%"></el-radio-button>
          <el-radio-button label="女" style="width: 50%"></el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="u_social">
        <el-select
          style="width: 100%"
          v-model="postInfo.city"
          @change="changeCity"
          placeholder="用证地区"
        >
          <el-option
            v-for="(item, index) in cityList"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          style="width: 100%"
          v-model="postInfo.certificate_name"
          @change="changeCer"
          placeholder="选择证书"
        >
          <el-option
            v-for="(item, index) in cerList"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-row :gutter="14">
        <el-col :span="12">
          <el-form-item>
            <el-select
              style="width: 100%"
              v-model="postInfo.certificate_major"
              placeholder="选择证书专业"
            >
              <el-option
                v-for="(item, index) in majorList"
                :key="index"
                :label="item.certificate_major"
                :value="item.certificate_major"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-select
              v-model="postInfo.certificate_status"
              placeholder="证书状态"
              style="width: 100%"
            >
              <el-option label="初始注册" value="初始注册"></el-option>
              <el-option label="转注册" value="转注册"></el-option>
              <el-option label="重新注册" value="重新注册"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-select
              v-model="postInfo.social"
              placeholder="社保要求"
              style="width: 100%"
            >
              <el-option label="唯一社保" value="唯一社保"></el-option>
              <el-option label="不唯一社保" value="不唯一社保"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-select
              v-model="postInfo.is_b"
              placeholder="是否有B证"
              style="width: 100%"
            >
              <el-option label="无B证" value="无B本"></el-option>
              <el-option label="有B证" value="有B本"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <el-input v-model="postInfo.phone" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input placeholder="请输入验证码" v-model="postInfo.code">
          <el-button
            slot="append"
            type="primary"
            v-prevent-repeat-click
            @click="gainCode"
            v-if="smsTime > 59"
          >
            获取验证码
          </el-button>
          <template slot="append" v-if="smsTime < 60">
            {{ smsTime }}秒后可重发
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          style="width:100%"
          class="radius-25 bc"
          type="primary"
          v-prevent-repeat-click
          @click="postAssess"
        >
          免费估价
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 估价dialog -->
    <el-dialog
      title="."
      :visible.sync="gujiaShow"
      width="600px"
      :close-on-click-modal="false"
      class="gujiaBg"
    >
      <div class="text-right gujiaClose">
        <i class="el-icon-circle-close" @click="gujiaCloseBtn"></i>
      </div>
      <div class="text-center gujiatitle">
        恭喜您估价成功
      </div>
      <div class="text-center gujiaBox">
        <span class="gujiaNum">{{ gujiaNum }}</span>
        <span class="gujiayuan">元</span>
      </div>
      <div class="text-center gujiafooter">
        <img :src="closeBtn" alt="" @click="gujiaCloseBtn" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { provinceAndCityData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      closeBtn: require("@/assets/img/gujiabtn.png"),
      proviceData: provinceAndCityData,
      cityList: [
        { label: "北京", value: "北京" },
        { label: "上海", value: "上海" },
        { label: "河北", value: "河北" },
        { label: "天津", value: "天津" },
        { label: "四川", value: "四川" },
        { label: "广东", value: "广东" },
        { label: "江苏", value: "江苏" },
        { label: "山东", value: "山东" },
        { label: "浙江", value: "浙江" },
        { label: "安徽", value: "安徽" },
        { label: "陕西", value: "陕西" },
        { label: "重庆", value: "重庆" },
        { label: "河南", value: "河南" },
        { label: "福建", value: "福建" },
        { label: "湖北", value: "湖北" },
        { label: "湖南", value: "湖南" },
        { label: "江西", value: "江西" },
        { label: "辽宁", value: "辽宁" },
        { label: "山西", value: "山西" },
        { label: "广西", value: "广西" },
        { label: "云南", value: "云南" },
        { label: "黑龙江", value: "黑龙江" },
        { label: "吉林", value: "吉林" },
        { label: "甘肃", value: "甘肃" },
        { label: "内蒙古", value: "内蒙古" },
        { label: "海南", value: "海南" },
        { label: "贵州", value: "贵州" },
        { label: "新疆", value: "新疆" },
        { label: "青海", value: "青海" },
        { label: "宁夏", value: "宁夏" },
        { label: "西藏", value: "西藏" },
      ],
      selectedOptions: [],
      postInfo: {},
      cerList: [
        { label: "一级建造师", value: "一级建造师" },
        { label: "二级建造师", value: "二级建造师" },
        { label: "中级职称", value: "中级职称" },
        { label: "高级职称", value: "高级职称" },
        { label: "公用设备工程师", value: "公用设备工程师" },
        { label: "电气工程师", value: "电气工程师" },
        { label: "一级造价", value: "一级造价" },
        { label: "二级造价", value: "二级造价" },
        { label: "监理工程师", value: "监理工程师" },
        { label: "土木工程师", value: "土木工程师" },
        { label: "结构工程师", value: "结构工程师" },
        { label: "注册咨询师", value: "注册咨询师" },
        { label: "建筑师", value: "建筑师" },
        { label: "消防工程师", value: "消防工程师" },
        { label: "八大员", value: "八大员" },
        { label: "技工证", value: "技工证" },
      ],
      majorList: [],
      selCerList: [],
      num: "",
      smsTime: 60,
      gujiaShow: false,
      gujiaNum: "",
    };
  },
  methods: {
    // 设置城市
    setcity() {
      this.postInfo.j_province = CodeToText[this.selectedOptions[0]];
      this.postInfo.j_city = CodeToText[this.selectedOptions[1]];
    },
    // 选择地区
    changeCity() {
      if (this.postInfo.certificate_name) {
        let info = {
          city: this.postInfo.city,
          certificate_name: this.postInfo.certificate_name,
        };
        this.$http.post("index/home/getassess", info).then((res) => {
          this.majorList = res.data.resultData;
        });
      }
    },
    // 选择证书
    changeCer() {
      if (!this.postInfo.city) {
        this.$message.warning("请选择用证地区！");
        return;
      }
      let info = {
        city: this.postInfo.city,
        certificate_name: this.postInfo.certificate_name,
      };
      this.$http.post("index/home/getassess", info).then((res) => {
        this.majorList = res.data.resultData;
      });
    },
    gainCode() {
      if (!this.postInfo.phone) {
        this.$message.warning("手机号码不能为空！");
        return;
      }
      let tel = "+86" + this.postInfo.phone;
      this.$http.post("index/login/assesscode", { phone: tel }).then((res) => {
        if (res.data.resultCode === 1) {
          // 验证码发送定时器
          let _this = this;
          let timer = setInterval(function() {
            _this.smsTime--;
            if (_this.smsTime === 0) {
              clearInterval(timer);
              _this.smsTime = 60;
            }
          }, 1000);
          this.$message.success("验证码已发送，请您注意查收！");
        }
      });
    },
    // 提交估价
    postAssess() {
      this.$http.post("index/home/assess", this.postInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.gujiaShow = true;
          this.gujiaNum = res.data.resultData[0];
        }
      });
    },
    // 获取今日多少人估价
    getNum() {
      let date = Math.round(Date.now() / 10000);
      this.num = Math.round(date.toString().substr(5, 5) / 10);
    },
    gujiaCloseBtn() {
      this.postInfo = {};
      this.gujiaShow = false;
    },
  },
  mounted() {
    // this.getCerList();
    this.getNum();
  },
};
</script>
<style scoped>
/deep/ .gujiaBg .el-dialog {
  background: url("../../assets/img/gujiabg.png");
  height: 458px;
  box-shadow: none;
}
/deep/ .gujiaBg .el-dialog .el-dialog__header {
  opacity: 0;
}
.assessBox {
  padding: 24px 24px 10px;
  background: linear-gradient(180deg, #33b6fe 0%, #2573f1 100%);
  border-radius: 4px;
  margin-bottom: 20px;
}
.gujiaClose {
  padding-right: 15px;
  padding-top: 15px;
}
.gujiaClose i {
  font-size: 24px;
  color: #fff;
}
.gujiaClose i:hover {
  opacity: 0.6;
}
.gujiatitle {
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
  line-height: 50px;
  margin-top: 43px;
}
.gujiaBox {
  font-size: 20px;
  color: #ff5f59;
  padding-top: 82px;
  padding-bottom: 28px;
}
.gujiaNum {
  font-size: 46px;
  font-weight: bold;
  color: #ff5f59;
}
.gujiayuan {
  position: relative;
  top: -10px;
  left: 5px;
}
.gujiafooter img:hover {
  opacity: 0.9;
}
.bc {
  background-color: #0450c5;
}
/deep/ .el-radio-button__inner {
  width: calc(100% - 5px);
}
/deep/ .el-radio-button:first-child .el-radio-button__inner {
  border-radius: 4px;
}
/deep/ .el-radio-button:last-child {
  text-align: right;
}
/deep/ .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 4px;
}
/deep/ .el-input-group__append {
  border: 1px solid #2572f1;
}
/deep/ .assessBox .el-button.el-button--primary {
  border: 1px solid #2572f1;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.assessTitle p:first-child {
  font-size: 17px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #00145b;
  text-align: center;
  padding-bottom: 3px;
}
.assessTitle p:last-child {
  font-size: 17px;
  font-family: MicrosoftYaHei;
  color: #00235b;
  line-height: 24px;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 20px;
}
</style>
